.banner-container {
  width: 80%;
  max-width: 1440px;
  margin: 90px auto 0 auto;
}
.banners {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding: 220px 0 40px 0;
}
.banner-right {
  position: relative;
  height: 100%;
}

.banner-right-content__img {
  width: 100%;
  transform: scale(1.4);
  position: absolute;
  z-index: -1;
  bottom: 90px;
}
.banner__course-logos img {
  width: 100%;
}
.person-writeup {
  position: absolute;
  padding: 10px;
  border-radius: 5px;
  width: 50%;
  left: 20%;
  bottom: 0;
  transform: translateX(-50%);
  background: rgba(223, 223, 223, 0.5);
  z-index: 10;
  box-shadow: 2px 2px 10px rgba(215, 215, 215, 0.5);
}
.person-writeup__title {
  text-align: center;
}
.person-writeup h4 {
  background-color: #ffffff;
  display: inline-block;
  font-size: 14px;
  color: #222222;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: 500;
}
.skills__set {
  text-align: center;
}
.skills__set h5 {
  margin-bottom: 15px;
}
.skills__set h3 {
  font-size: 22px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  color: #222222;
  margin: 10px 0;
}
.skills__set h3 p {
  font-size: 14px;
  font-weight: 700;
}
.skills__set h4 {
  color: #ffa41d;
  font-weight: 700;
}
/* Banner Left */
.banner-left {
  width: 100%;
}
.banner-left h2 {
  font-size: 75px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  line-height: 1.4;
}
.banner-left h2 span {
  color: #00afef;
}
.banner-left p {
  font-size: 18px;
  color: #555555;
  line-height: 1.6;
  margin: 20px 0;
  max-width: 80%;
}
.banner-left__links {
  margin: 40px 0 20px 0;
}
.banner-left__link {
  margin-right: 20px;
  text-decoration: none;
  padding: 12px 20px 13px 20px;
  font-size: 14px;
  margin: 20px 20px 20px 0;
  text-align: center;
  font-weight: 500;
}
.banner-left__link1 {
  background-color: #00afef;
  color: #ffffff;
  max-width: 80%;
  border-radius: 5px;
  border: 1px solid #00afef;
}
.banner-left__link2 {
  border: 1px solid #00afef;
  color: #00afef;
  max-width: 80%;
  border-radius: 5px;
}
/* banner Bottom */
.banner__sub-section {
  background-color: #f0f0f0;
  padding: 10px 0;
}
.banner__course-logos {
  width: 80%;
  margin: 10px auto;
}
.b__course-logos-header {
  margin-bottom: 30px;
}
.b__course-logos-header h4 {
  color: #222222;
  font-family: "Fredoka", sans-serif;
  font-size: 19px;
}
.banner__course-logos img {
  height: 50px;
  width: 100%;
  object-fit: contain;
  display: block;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* Why Choose US */
.why-choose-us {
  padding: 40px 0;
  background-color: #fafafa;
}
.wcu__contents {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  text-align: center;
}
.wcu__content h4 {
  font-size: 22px;
  margin-top: 20px;
}
.wcu__content img {
  display: flex;
  margin: 0 auto;
  height: 100px;
}
/* Testimonials */
.testimonials__header2 {
  font-family: "Fredoka", sans-serif;
  font-size: 35px;
  margin-bottom: 40px;
}
.testimonials__slides {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}
.testimonials__slide {
  width: 100%;
  border-radius: 10px;
  box-shadow: 2px 2px 10px rgba(215, 215, 215, 0.5);
  padding: 20px;
}
.t__slide-text {
  line-height: 2.1;
  color: #333333;
  font-size: 17px;
  margin-bottom: 10px;
}
.t__slide-descrp {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.t__slide-descrp img {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 50%;
}
.t__slide-title {
  margin-left: 5px;
}
.t__slide-title h3 {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 5px;
}
.t__slide-title p {
  font-size: 14px;
  color: #888888;
  font-weight: 500;
}
/* Get Skilled */
.get-skilled__grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  place-items: center;
  font-family: "Montserrat", sans-serif;
}
.get-skilled__left h5 {
  text-transform: uppercase;
  font-size: 14px;
  color: #00afef;
  text-decoration: underline;
  margin-bottom: 10px;
  font-weight: 900;
}
.get-skilled__right img {
  width: 100%;
  height: 520px;
}
.get-skilled__left h2 {
  font-size: 50px;
  margin-bottom: 30px;
}
.get-skilled__left p {
  font-size: 17px;
  line-height: 2.2;
  color: #333333;
}
.get-skilled__link {
  display: inline-block;
  margin-top: 30px;
  padding: 10px 20px 11px 20px;
  font-size: 14px;
  font-weight: 500;
  color: #00afef;
  border: 1px solid #00afef;
  text-decoration: none;
  border-radius: 5px;
}
/* Get Trained */
.get-trained {
  margin-top: 50px;
}
.get-trained__content {
  margin: 50px auto;
  padding: 40px 0;
}
.get-trained__grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 100px;
  align-items: center;
}
.gt__left-container {
  border-radius: 10px;
  box-shadow: 2px 2px 10px rgba(228, 228, 228, 0.5);
}
.gt-left__slides {
  box-shadow: 2px 2px 10px rgba(218, 218, 218, 0.5);
  margin-right: -50px;
  border-radius: 10px;
  padding: 10px 20px;
  background-color: #ffffff;
  margin-left: 40px;
  font-family: "Montserrat", sans-serif;
}
.gt-left__slides p {
  font-size: 18px;
  font-weight: 500;
}
.gt-left__slides-content {
  display: flex;
  margin-left: auto;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
.gt-left__slides-content img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  padding: 7px;
  box-shadow: 2px 2px 10px rgba(215, 215, 215, 0.5);
  margin-left: 20px;
}
.gt-left__slider-dots {
  padding: 17px 15px;
  background-color: #f5f5f5;
  border-top-right-radius: 10px;
  display: flex;
  justify-content: flex-end;
  text-align: right;
}
.gt-slides__dot {
  height: 7px;
  width: 7px;
  border-radius: 50%;
  margin: 0 5px;
}
.gt-slides__dot-red {
  background: red;
}
.gt-slides__dot-yellow {
  background: orange;
}
.gt-slides__dot-green {
  background: green;
}
.gt-slides__container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 40px;
  padding: 50px 0;
}
.gt__right-header {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 10px;
  font-family: "Montserrat", sans-serif;
}
.gt__right-txt h3 {
  font-size: 60px;
  margin-bottom: 10px;
  color: #00afef;
}
.gt__right-txt p {
  font-size: 17px;
  color: #333333;
  font-weight: 700;
}
.gt__right-para p {
  font-size: 16px;
  line-height: 1.8;
  color: #555555;
  font-family: "Montserrat", sans-serif;
  margin: 30px 0;
}
.gt__right-links {
  margin-top: 40px;
}
.gt__right-link {
  padding: 10px 20px 11px 20px;
  font-size: 14px;
  background-color: #00afef;
  color: #ffffff;
  text-decoration: none;
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;
}
/* FAQ */
.FAQ-section {
  width: 100%;
}
.FAQ-subs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}
.FAQ-descrp h2 {
  font-size: 50px;
  margin-bottom: 30px;
  font-weight: 700;
  color: #1b093d;
}
.FAQ-descrp p {
  margin-bottom: 20px;
  color: #222222;
}
.FAQ-descrp a {
  text-decoration: none;
  background-color: #00afef;
  color: #ffffff;
  display: inline-block;
  padding: 10px 20px;
  font-size: 14px;
  margin-top: 20px;
  border-radius: 5px;
}
.accord__container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}
.accord__item {
  border: 1px solid #777777;
  border-radius: 10px;
  padding: 15px;
}
.accord_title {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.accord_title h3 {
  font-size: 18px;
  color: #1b093d;
}
.accord__descrp {
  /* display: none; */
  margin-top: 10px;
}
.accord__descrp p {
  line-height: 1.9;
  color: #333333;
}
.accordion__icon {
  font-size: 20px;
}
.accord__descrp.active {
  display: block;
}
.accord__descrp.inactive {
  display: none;
}
/* Media */
@media (max-width: 1670px) {
  .banners {
    padding: 160px 0 40px 0;
  }
  .banner-right-content__img {
    bottom: 70px;
  }
}
@media (max-width: 1440px) {
  .banner-left h2 {
    font-size: 60px;
  }
}
@media (max-width: 1300px) {
  .banners {
    padding: 120px 0 40px 0;
  }
  .banner-right-content__img {
    transform: scale(1.22);
  }
  .banner-left p {
    max-width: 100%;
  }
  .banner-container {
    width: 90%;
  }
  .banner-right-content__img {
    bottom: 20px;
  }
  .person-writeup {
    width: 60%;
    left: 40%;
  }
  .banner__course-logos {
    width: 90%;
    margin: 10px auto;
  }
  .get-skilled__right img {
    height: 100%;
  }
}
@media (max-width: 1255px) {
  .banner-left h2 {
    font-size: 55px;
  }
}
@media (max-width: 1080px) {
  .banners {
    padding: 80px 0 40px 0;
  }
  .banner-left h2 {
    font-size: 50px;
  }
}
@media (max-width: 990px) {
  .banner-container {
    width: 90%;
  }
  .banners {
    grid-template-columns: repeat(1, 1fr);
    padding-top: 70px;
    min-height: auto;
    text-align: center;
    gap: 30px;
  }
  .banner-right__circle {
    width: 90%;
    margin: 0 auto;
  }
  .banner-right-content__img {
    position: relative;
    bottom: 50px;
    width: 100%;
    margin-top: 50px;
  }
  .person-writeup {
    width: 40%;
    left: 20%;
  }
  .get-skilled__grid {
    grid-template-columns: repeat(1, 1fr);
    gap: 5px;
  }
  .get-skilled__right {
    grid-column-start: 1;
    grid-row-start: 1;
  }
  .get-trained__grid {
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }
  .gt-left__slides {
    box-shadow: 2px 2px 10px rgba(218, 218, 218, 0.5);
    margin-right: 0;
    margin: 0 20px;
  }
  .get-trained__right {
    margin: 15px;
  }
  .sub-header {
    text-align: center;
    margin: 0 10px 50px 10px;
  }
}
@media (max-width: 768px) {
  .banners {
    padding: 50px 0 10px 0;
  }
  .banner-left h2 {
    font-size: 50px;
  }
  .banner-right__circle {
    width: 100%;
  }
  .banner-right-content__img {
    transform: scale(1.1);
    bottom: 0px;
    margin-top: 0;
  }
  .person-writeup {
    width: 60%;
    left: 30%;
  }
  .get-skilled__left h2 {
    font-size: 40px;
  }
  .sub-header__title {
    font-size: 30px;
  }
  .gt__right-txt h3 {
    font-size: 40px;
  }
  .gt__right-txt p {
    font-size: 15px;
  }
  .FAQ-descrp h2 {
    font-size: 35px;
  }
  .wcu__contents,
  .testimonials__slides,
  .FAQ-subs {
    grid-template-columns: repeat(1, 1fr);
    gap: 40px;
  }
  .get-trained__content {
    margin: 0;
    padding: 0;
  }
  .gt__right-header {
    grid-template-columns: 1fr 1fr;
  }
  .why-choose-us {
    padding: 30px 0;
  }
}
@media (max-width: 500px) {
  .banners {
    padding-top: 40px;
  }
  .banner-right-content__img {
    transform: scale(1.3);
  }
  .banner-right__circle {
    overflow: hidden;
  }
  .person-writeup {
    width: 100%;
    left: 50%;
  }
  .banner-left h2 {
    font-size: 35px;
  }
  .skills__set h3 {
    font-size: 17px;
  }
  .person-writeup h4 {
    font-size: 13px;
  }
  .skills__set h3 p {
    font-size: 13px;
  }
  .get-skilled__left h2 {
    font-size: 25px;
  }
  .testimonials__header2 {
    font-size: 30px;
  }
  .accord_title h3 {
    font-size: 16px;
  }
  .gt-left__slides p {
    font-size: 15px;
  }
  .sub-header__title {
    font-size: 25px;
  }
  .wcu__content h4 {
    font-size: 18px;
  }
}
