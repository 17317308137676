.why-rework {
  margin-top: 70px;
}
.wr-banner__content {
  min-height: 530px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.wr-banner__content h2 {
  font-size: 45px;
  margin: 20px 0;
  font-weight: 600;
  font-family: "Fredoka", sans-serif;
}
.wr-banner__content p {
  margin-top: 20px;
  font-size: 18px;
  line-height: 2.1;
  font-weight: 500;
  padding: 5px;
}
.wr-banner__content a {
  display: block;
  text-decoration: none;
  background-color: #00afef;
  color: #ffffff;
  padding: 12px 30px;
  border-radius: 5px;
  font-size: 14px;
  margin-top: 30px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.wr-banner__icon {
  font-size: 21px;
  margin-left: 5px;
}
.wr-banner__content2 {
  position: relative;
}
.wr-banner__content2 img {
  max-height: 550px;
  width: 100%;
  display: block;
  object-fit: cover;
  border-radius: 10px;
}
/* About */
.about__container {
  padding: 20px 0;
}
.about__container h3 {
  font-size: 35px;
  margin-bottom: 20px;
  font-weight: 500;
}
.about__container p {
  margin-bottom: 20px;
  line-height: 2.3;
  font-size: 18px;
}
.about__container img {
  width: 80%;
}
/* Mission */
.our-mission {
  background-color: #fbfbfb;
  padding: 5px 0;
}
.our-mission__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;
  align-items: center;
}
.misson__img {
  text-align: center;
}
.misson__img img {
  width: 70%;
}
.mission_content p {
  font-size: 18px;
  line-height: 2.1;
  color: #333333;
  margin-top: 10px;
}
/* Values */
.values__content-grids {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  text-align: center;
  margin: 40px 0;
  padding: 40px 0;
}
.v__content-grids-sub img {
  height: 90px;
}
.v__content-1 h2 {
  margin: 20px auto 40px auto;
  text-align: center;
  font-family: "Fredoka", sans-serif;
  font-size: 35px;
}
.v__content-1 h3 {
  font-size: 18px;
  font-weight: 600;
  margin: 10px 0;
}
.v__content-2 h4,
.testimonials__header,
.our-team__header,
.mission__header {
  text-decoration: underline;
  color: #00afef;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 15px;
  font-size: 700;
}
.v__content-2 p {
  line-height: 2.1;
  margin-bottom: 20px;
  font-size: 17px;
}
/* Our Team */
.our-team {
  margin: 70px 0;
}
.our-team__header-cont {
  margin-bottom: 40px;
}
.our-team__grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
}
.our-team__box {
  text-align: center;
}
.our-team__img1 {
  width: 250px;
  height: 280px;
}
.our-team__img1-div {
  filter: url(#round);
  width: 250px;
  height: 290px;
  margin: 0 auto;
}
.our-team__img2 {
  width: 100%;
  height: 100%;
  background-image: url("../Images/nature.jpg");
  object-fit: cover;
  clip-path: polygon(50% 0%, 100% 24%, 100% 76%, 50% 100%, 0 76%, 0 24%);
}
/* @media */
@media (max-width: 990px) {
  .our-team__grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 766px) {
  .our-mission__container,
  .values__content-grids,
  .our-team__grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .wr-banner__content h2 {
    font-size: 35px;
    margin: 0;
  }
  .wr-banner__content {
    min-height: 100%;
    padding: 20px 0;
  }
}
