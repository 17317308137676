.contact {
  position: relative;
}
.contact__container {
  padding: 50px 0;
}
.contact__content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;
  padding: 50px 0 0 0;
}
/* Contact Left */
.contact__left-header h2 {
  font-size: 42px;
  font-family: "Fredoka", sans-serif;
  margin-bottom: 20px;
  color: #1b093d;
}
.contact__left-header p {
  margin-bottom: 20px;
  color: #1b093d;
  font-weight: 500;
  font-size: 16px;
}
.contact__left-sect {
  display: flex;
  margin-bottom: 20px;
}
.contact__left-icon {
  flex-shrink: 0;
  padding-right: 5px;
  font-size: 25px;
  color: #1b093d;
  display: inline-flex;
  margin-top: 8px;
}
.contact__left-sect span {
  margin-left: 10px;
  line-height: 2.1;
  font-size: 16px;
  color: #1b093d;
  display: inline-flex;
}
/* Contact Right */
.contact__form-group {
  margin-bottom: 20px;
}
.contact__form-group label,
.contact__form-group input,
.contact__form-group textarea {
  display: block;
}
.contact__form-group label {
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: 500;
  color: #323232;
}
.contact__form-group input,
.contact__form-group textarea {
  padding: 12px 5px;
  border: 1px solid #323232;
  outline: none;
  width: 100%;
  border-radius: 5px;
}
.contact__form-group textarea {
  min-height: 120px;
}
/* Success Message */
.successMsgContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 20;
}
.successMsgContent {
  position: relative;
}
.successMsg {
  position: absolute;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  margin: 0 auto;
  box-shadow: 2px 2px 10px rgba(210, 210, 210, 0.5);
  background-color: white;
  z-index: 5;
  border-radius: 10px;
  padding: 40px;
}
.successMsg__close {
  position: absolute;
  right: -10px;
  top: -10px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.5s ease;
}
.successMsg__close:hover {
  transform: scale(1.1);
}
.sucessMsgCloseIcon {
  font-size: 22px;
}
.successMsg__descrp {
  text-align: center;
  margin: 20px 0;
}
.successMsg__descrp h2 {
  font-size: 35px;
  font-family: "Fredoka", sans-serif;
  color: #1b093d;
  margin: 10px 0;
}
.successMsg__descrp p {
  font-size: 16px;
  color: #222222;
  line-height: 1.8;
}
/* @media */
@media (max-width: 766px) {
  .contact__container {
    padding: 0;
  }
  .contact__content {
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }
}
@media (max-width: 500px) {
  .contact__left-sect span {
    margin-left: 5px;
    font-size: 14px;
  }
  .contact__left-icon {
    margin-top: 2px;
  }
}
