/* Webinar Form */
.webinar-form-container {
  min-height: calc(100vh - 50px);
  background-color: #fbfbfb;
}
.wf__container {
  padding: 50px 0;
  width: 80%;
  margin: auto;
}
.webinar__lists {
  margin-top: 50px;
  background-color: #fdfdfd;
  padding: 20px 0;
}
.webinar__grids {
  display: grid;
  gap: 10px;
}
.wb__lists_items {
  width: 70%;
  margin: 0 auto;
}
.wb__list {
  padding: 10px;
  border-radius: 5px;
  position: relative;
  background-color: #ffffff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 20px;

  text-decoration: none;
  color: #000000;
}
.wb__list:not(:nth-last-of-type(1)) {
  border-bottom: 1px solid #dedede;
}
.wb__list h3 {
  font-size: 22px;
  color: #234234;
  margin: 20px 0 10px 0;
  font-weight: 600;
  margin-bottom: 5px;
  padding-bottom: 5px;
}
.wb__list p {
  line-height: 1.8;
  margin: 10px 0;
}
.wb__list_descp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 100%;
}
.wb__list img {
  width: 100%;
  height: 250px;
  object-fit: contain;
  min-width: 250px;
  box-shadow: 2px 2px 10px rgba(231, 231, 231, 0.4);
}
.wb__list_dates {
  display: flex;
  gap: 10px;
}
.wb__list h4 {
  padding: 10px;
  font-size: 15px;
  background-color: #aeaefe;
  color: #fefefe;
  border-radius: 20px;
  align-items: center;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}
.wb__list h4 svg {
  margin-right: 4px;
}
.wb__list-link {
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 14px;
  padding: 10px 20px;
  background-color: #e7e7e7;
  color: #222222;
  border-radius: 2px;
  font-weight: 600;
  transition: padding 0.5s ease;
  text-decoration: none;
}
.wb__list-link:hover {
  padding: 15px 25px;
}
/* Form */
.wb__form-header {
  text-align: center;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #f2f1f2;
}
.wb__form-header h2 {
  padding: 5px 10px;
  font-family: "Fredoka", sans-serif;
  font-weight: 600;
}
.webinar__form {
  width: 45%;
  margin: 50px auto;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
}
.wb__form-group-sub {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}
.wb__form-group {
  margin-bottom: 10px;
}
.wb__form-group label,
.wb__form-group-radio label {
  display: block;
  font-weight: 500;
}
.wb__form-group input,
.wb__form-group textarea {
  padding: 13px 5px;
  outline: none;
  border: 1px solid #dedede;
  width: 100%;
  border-radius: 5px;
}
.wb__form-group-radio div {
  display: flex;
  gap: 5px;
  margin: 7px 0;
}
.wb__form-group-radio input {
  width: 20px;
  height: 20px;
}
.wf__group-btn {
  margin-top: 20px;
}
.wf__group-btn button {
  cursor: pointer;
  border: none;
  padding: 13px 50px;
  background-color: #00afef;
  color: #ffffff;
  border-radius: 5px;
  font-size: 15px;
}
.wbf-err {
  border: 1px solid #fe5f5f !important;
}
.wb__form-group-radio span,
.wbf__err {
  color: #fe5f5f;
  font-size: 15px;
  font-weight: 500;
}
.networkErr {
  padding: 20px 0;
  text-align: center;
  border-radius: 5px;
  margin: 20px 0;
  font-weight: 500;
  font-size: 2.5rem;
  font-family: "Fredoka", sans-serif;
}
.successWebContainer {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}
.successWeb {
  width: 40%;
  background-color: #ffffff;
  border-top: 5px solid #00afef;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  position: relative;
  display: block;
}
.wb__success {
  padding: 20px;
  margin: 10px 0;
  box-shadow: 2px 2px 10px rgba(209, 209, 209, 0.4);
  text-align: center;
}
.wb__success-icon {
  font-size: 75px;
  color: #00afef;
}
.successWeb h2 {
  font-size: 40px;
  font-weight: 500;
  margin: 20px 0;
}
.successWeb h4 {
  margin-bottom: 20px;
}
.successWeb p {
  line-height: 1.8;
  font-size: 16px;
  width: 60%;
  margin: 10px auto;
  color: #251425;
}
.wb__close {
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 25px;
  background: #ffffff;
  box-shadow: 2px 2px 10px rgba(209, 209, 209, 0.4);
  border: none;
  border-radius: 10px;
  padding: 7px 5px 5px 5px;
  cursor: pointer;
  transition: padding 0.3s ease;
}
.wb__close:hover {
  padding: 10px 8px 8px 8px;
}
.wb_errmsg {
  background-color: #eb2222;
  color: #ffffff;
  padding: 20px 0;
  margin: 0 0 30px 0;
  border-radius: 5px;
  text-align: center;
}
.webinar_loading_icon {
  text-align: center;
  margin: 20px 0;
}
.web_loading_icon {
  font-size: 35px;
  animation: webinar_loader 1.5s infinite linear;
  -webkit-animation: webinar_loader 1.5s infinite linear;
  color: #00afef;
}

/* Events Details */
/* .eventDetails_container {
  background-color: #f9f9f9;
} */
.eventDetails_grid {
  margin-top: 90px;
  width: 60%;
  margin: 90px auto 0 auto;
  display: grid;
  grid-template-columns: 2fr 4fr;
  gap: 20px;
  padding: 40px 5px;
}
.event_details_1 img {
  width: 100%;
  min-width: 320px;
  box-shadow: 2px 2px 10px rgba(237, 237, 237, 0.4);
  padding: 2px;
}
.event_c_dts_header {
  margin-bottom: 20px;
}
.event_c_dts_header h2 {
  font-size: 40px;
  font-weight: 600;
}
.event_c__dts {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
}
.event_c__dts svg {
  font-size: 30px;
  border: 1px solid #dedede;
  padding: 4px;
  color: #00afef;
}
.event_content_2 {
  border: 1px solid #f1f1f1;
  box-shadow: 2px 2px 10px rgba(232, 232, 232, 0.4);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  margin: 20px 0;
  background-color: #ffffff;
}
.event_content_2 h3 {
  background-color: #efefef;
  padding: 5px 20px;
  font-weight: 600;
  font-size: 16px;
}
.event_content_2 p {
  padding: 10px 20px;
}
.event_content_2 a {
  display: block;
  background-color: #00afef;
  color: #ffffff;
  margin: 5px 20px 10px 20px;
  border-radius: 5px;
  padding: 8px;
  text-decoration: none;
  text-align: center;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  font-size: 15px;
  font-weight: 500;
}
.event_content3_header {
  margin: 20px 0 10px 0;
  padding-bottom: 5px;
  border-bottom: 1px solid #dedede;
}
.event_content3_header h3 {
  font-size: 16px;
}
.event_content3_title {
  margin: 20px 0;
}
.event_content3_alt {
  margin-bottom: 15px;
}
.event_content_3 strong {
  font-size: 15px;
}
.event_content3_descpr h4 {
  margin: 10px 0 0 0;
}
.event_content3_descpr p {
  font-size: 15px;
  line-height: 1.6;
  color: #565555;
}
.no_event_err {
  padding: 100px 0;
}
.no_event_details_err {
  margin-top: 100px;
}
.web_details_loading {
  padding: 100px 0;
  margin-top: 100px;
}
@media (max-width: 1440px) {
  .webinar__form,
  .successWeb {
    width: 55%;
  }
  .eventDetails_grid {
    width: 80%;
  }
}
@media (max-width: 1024px) {
  .webinar__form,
  .successWeb {
    width: 75%;
  }
  .successWeb h2 {
    font-size: 35px;
  }
  .wb__success {
    padding: 10px;
  }
  .wb__success-icon {
    font-size: 60px;
  }
  .wb__lists_items {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .webinar__form,
  .successWeb {
    width: 95%;
  }
  .wb__success-icon {
    font-size: 50px;
  }
  .wb__form-group-sub {
    grid-template-columns: repeat(1, 1fr);
  }
  .successWeb p {
    width: 100%;
  }
  .wb__close {
    font-size: 20px;
  }
  .networkErr {
    font-size: 1.5rem;
  }
  .wb__list {
    grid-template-columns: 1fr;
  }
  .wb__list-link {
    position: static;
    text-align: right;
    display: inline-block;
  }
  .wb__list_link_container {
    text-align: right;
    margin-top: 5px;
  }
  .wb__list h4 {
    padding: 5px;
    font-size: 14px;
  }
  .no_event_err {
    padding: 30px 0;
  }
  .eventDetails_grid {
    width: 98%;
  }
  .event_c_dts_header h2 {
    font-size: 30px;
  }
}
@media (max-width: 650px) {
  .wf__container {
    width: 95%;
  }
  .webinar__form {
    width: 100%;
    padding: 10px;
  }
  .successWeb h2 {
    font-size: 27px;
  }
  .successWeb h4 {
    font-size: 15px;
  }
  .webinar__lists {
    margin-top: 0;
  }
  .eventDetails_grid {
    grid-template-columns: 1fr;
  }
  .event_details_1 {
    text-align: center;
  }
  .event_details_1 img {
    width: 80%;
  }
  .event_c_dts_header h2 {
    font-size: 25px;
  }
}
@keyframes webinar_loader {
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
  }
}
