.course-banner__section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  align-items: center;
  padding: 50px 0 10px 0;
}
.cb__left-subtitle span {
  background-color: #d1f0fc;
  padding: 10px;
  color: #333333;
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
}
.cb__left-header h2 {
  font-family: "Fredoka", sans-serif;
  font-size: 55px;
  margin-bottom: 10px;
  color: #1b093d;
}
.cb__left-header p {
  line-height: 2.2;
  font-size: 17px;
  color: #333333;
  margin: 30px 0;
}
.cb__left-nsd p {
  margin-top: 20px;
  font-size: 16px;
}
.cb__left-nsd p span {
  font-weight: 500;
}
.course-banner__right {
  width: 100%;
}
.course-banner__right img {
  width: 90%;
  object-fit: cover;
  display: block;
  margin: 0 auto;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  /* max-height: 500px; */
  min-width: 300px;
  object-fit: cover;
}
.course_banner--self-pace-btn {
  margin-top: 10px;
}
.cb__left-links a {
  margin-bottom: 10px;
}
.course-banner__learning_modes {
  padding: 20px;
  background-color: #f3f3f3;
  margin-top: 20px;
}
.course-banner__learning_modes_sections {
  width: 65%;
  margin: 0 auto;
  max-width: 1440px;
}
.course-banner__learning_mode_section {
  display: flex;
  justify-content: space-between;
  text-align: center;
  gap: 10px;
}
.course-banner__learning_mode_sects {
  width: 100%;
}
.course-banner__learning_mode_sects svg {
  font-size: 18px;
}
.course-banner__learning_mode_sects p {
  font-size: 16px;
  color: #636363;
}
/* Course Modal */
.course-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}
.course-modal__content {
  width: 500px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 5px;
}
.course-modal__content h2 {
  margin-bottom: 10px;
  border-bottom: 1px solid #cecece;
  font-weight: 500;
  font-size: 20px;
  padding: 5px 0;
}
.course-modal__content p {
  line-height: 1.6;
  margin-bottom: 10px;
  color: #616161;
}
.course-modal__inputs {
  display: grid;
  gap: 10px;
}
.course-modal__inputs div {
  display: flex;
  align-items: center;
  gap: 10px;
}
.course-modal__btns {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.course-modal__btns button {
  padding: 10px 20px 11px 20px;
  margin-right: 0;
}
.course-modal__view {
  display: none;
}
/* Course Module */
.cm__section1 {
  text-align: center;
  margin-bottom: 80px;
}
.cm__section1 h3 {
  font-size: 22px;
  color: #1b093d;
}
.cm__section1 p {
  width: 68%;
  margin: 10px auto;
  line-height: 2.5;
  color: #1b093d;
  font-size: 17px;
}
.cm__section2 {
  width: 60%;
  margin: 70px auto;
}
.cm__section2 h3 {
  font-family: "Fredoka", sans-serif;
  font-size: 40px;
  margin-bottom: 50px;
  color: #1b093d;
  text-align: center;
}
.wywl__section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-left: 15%;
  gap: 30px;
}
.wywl__sub {
  margin-bottom: 10px;
}
.wywl__sub {
  display: flex;
  align-items: center;
}
.wywl__sub img {
  width: 32px;
}
.wywl__sub p {
  margin-left: 20px;
  font-weight: 500;
  font-size: 18px;
}
.co__sections {
  display: flex;
  justify-content: center;
  gap: 40px;
  flex-wrap: wrap;
}
.co__section {
  width: 100%;
  max-width: calc(33.33% - 40px);
  border: 1px solid #1b093d;
  border-radius: 10px;
  position: relative;
  background-color: #ffffff;
  min-width: 290px;
}
.co__section::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 20px;
  width: 100%;
  background-color: #1b093d;
  margin-left: -20px;
  margin-top: -20px;
  border-radius: 10px;
  z-index: -1;
}
.co__section-title {
  margin: 40px 0;
}
.co__section-title h3,
.adm__section-header h3,
.tuition__header h3 {
  text-align: center;
  font-family: "Fredoka", sans-serif;
  font-size: 40px;
  color: #1b093d;
  text-align: center;
}
.co__section-title h3 {
  font-size: 32px;
}
.co__section-box {
  padding: 20px;
}
.co__box-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.co__box-header h4 {
  font-size: 15px;
}
.co__box-fee {
  font-weight: 700 !important;
}
.co__box-header img {
  width: 12px;
}
.co__box-sub {
  margin-bottom: 20px;
}
.co__box-sub span {
  font-size: 15px;
  font-weight: 500;
}
.co__box-grid p {
  margin-bottom: 25px;
  display: flex;
  align-items: center;
}
.co__box-icon {
  font-size: 20px;
  margin-right: 7px;
}
.co__box-grid p span {
  font-size: 15px;
  font-weight: 500;
  display: inline-block;
  margin-right: 5px;
  position: relative;
}
.co__box-icon p span:nth-of-type(1)::after {
  content: "&";
  position: absolute;
  left: 0;
  right: 0;
  background-color: red;
}
.co__box-icon_span:not(:last-of-type)::after {
  content: "&";
}
/* Admission Process */
.admission-process {
  background-color: #fbfbfb;
  padding: 30px 0;
}
.adm__section {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-top: 40px;
}
.adm__sect-box {
  padding: 10px;
  border-radius: 10px;
  background-color: #1b093d;
  color: #ffffff;
  text-align: center;
}
.adm__sect-box p {
  font-size: 18px;
  line-height: 1.8;
}
.adm__icon {
  font-size: 100px;
}
/* Why Take Course */
.why_choose_us .adm__section {
  grid-template-columns: repeat(3, 1fr);
}
.why_choose_us .adm__sect-box h3 {
  margin-bottom: 5px;
}
/* Tuition */
.tuition__header {
  text-align: center;
}
.tuition__header p {
  font-size: 18px;
  color: #1b093d;
  line-height: 1.8;
}
.tuition__sections {
  display: flex;
  margin-top: 30px;
}
.tuition__section {
  width: 100%;
}
.tuition__section-header {
  text-align: center;
  border-bottom: 1px solid #dedede;
  padding: 20px 0;
  background-color: #f6f6f6;
}
.ts__header-1 {
  border-right: 1px solid #dedede;
}
.ts__content {
  padding: 20px;
  text-align: center;
}
.ts__content span {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}
.ts__content h2 {
  margin: 10px 0;
  font-size: 40px;
  color: #1b093d;
}
.ts__content p {
  font-size: 17px;
  line-height: 2.1;
  color: #1b093d;
}
.courseHeight {
  min-height: 100vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;
  margin-top: 102px;
}
.loadningAnim {
  background-color: #dedede;
  animation: fadeIn 1.5s linear infinite alternate;
  border-radius: 5px;
  margin-bottom: 20px;
}
.courseHeight-right {
  height: 60%;
  background-color: #dedede;
  animation: fadeIn 1s linear infinite alternate;
  border-radius: 10px;
  border-radius: 50%;
}
.courseLodingText1 {
  height: 20px;
}
.courseLodingText2 {
  height: 40px;
}
.courseLodingText3 {
  height: 90px;
}
.courseLoadinBtns {
  display: inline-block;
  height: 20px;
  width: 35%;
  margin-right: 20px;
}

@keyframes fadeIn {
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
/* @media */
@media (max-width: 1400px) {
  .course-banner__learning_modes_sections {
    width: 80%;
  }
}
@media (max-width: 1300px) {
  .course-banner__learning_modes_sections {
    width: 90%;
  }
}
@media (max-width: 1200px) {
  .cb__left-header h2 {
    font-size: 40px;
  }
  .wywl__section {
    margin-left: 0;
  }
}
@media (max-width: 990px) {
  .course-banner__section {
    grid-template-columns: repeat(1, 1fr);
    padding: 20px 0;
  }
  .courseHeight {
    grid-template-columns: repeat(1, 1fr);
  }
  .course-banner__right img {
    width: 80%;
  }
  .courseHeight {
    gap: 20px;
  }
  .courseHeight-right {
    height: 300px;
  }
  .course-banner__learning_modes_sections {
    width: 100%;
  }
  .course-banner__learning_mode_sects svg {
    font-size: 16px;
  }
  .course-banner__learning_mode_sects p {
    font-size: 14px;
  }
  .cm__section2 {
    width: 80%;
  }
}
@media (max-width: 768px) {
  .cb__left-header h2 {
    font-size: 30px;
  }
  .cb__left-header p {
    margin: 15px 0;
  }
  .course-banner__section {
    grid-template-columns: repeat(1, 1fr);
  }
  .course-banner__right img {
    width: 100%;
  }
  .course-banner__learning_mode_section {
    flex-direction: column;
    gap: 20px;
  }
  .cm__section2 {
    width: 100%;
  }
  .cm__section2 h3 {
    font-size: 30px;
  }
  .cm__section1 p {
    width: 95%;
  }
  .wywl__section {
    margin-left: 0;
  }
  .co__section-title h3,
  .adm__section-header h3,
  .tuition__header h3 {
    font-size: 30px;
  }
  .co__sections {
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 0 20px;
  }
  .co__section {
    max-width: 100%;
  }
  .adm__section {
    grid-template-columns: repeat(2, 1fr);
  }
  .why_choose_us .adm__section {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media (max-width: 600px) {
  .cb__left-subtitle span {
    font-size: 12px;
    display: block;
  }
  .adm__section {
    grid-template-columns: repeat(1, 1fr);
  }
  .adm__sect-box {
    padding: 20px;
  }
  .tuition__sections {
    flex-direction: column;
  }
  .wywl__section {
    gap: 10px;
  }
  .wywl__sub img {
    width: 23px;
  }
  .wywl__sub p {
    margin-left: 10px;
    font-size: 16px;
  }
  .course-banner__right img {
    width: 100%;
  }
  .ts__content h2 {
    font-size: 30px;
  }
  .cb__left-nsd p {
    font-size: 13px;
  }
}
@media (max-width: 500px) {
  .cb__left-links a,
  .cb__left-links button,
  .course_banner--self-pace-btn a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .cb__left-links a,
  .cb__left-links button {
    margin-bottom: 0;
    margin-top: 6px;
  }
  .course-modal__content {
    min-width: 300px;
    margin: 0 10px;
    padding: 10px;
  }
  .courses {
    min-width: 320px;
  }
}
