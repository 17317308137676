.courses-banner {
  margin-top: 100px;
}
.courses-banner__containers {
  padding: 30px 0;
}
.courses-banner__header {
  text-align: center;
}
.courses-banner__header h2 {
  font-size: 45px;
  font-weight: 600;
  margin: 20px 0;
  font-family: "Fredoka", sans-serif;
  color: #1b093d;
  text-transform: capitalize;
}
.courses-banner__header p {
  margin-top: 20px;
  font-size: 15px;
  line-height: 2.1;
  font-weight: 500;
  padding: 5px;
}
.coursecard__container {
  background-color: #fdfdfd;
  padding: 20px 0;
}
.coursecard__content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 60px 30px;
}
.coursecard__link {
  text-decoration: none;
  box-shadow: 2px 2px 10px rgba(210, 210, 210, 0.5);
  border-radius: 10px;
  padding: 10px;
  background-color: #ffffff;

  position: relative;
}
.coursecard {
  border-radius: 10px;
}
.coursecard__img img {
  width: 100%;
  height: 250px;
  object-fit: contain;
  display: block;
  padding: 10px;
}
.coursecard__descrp h3 {
  font-size: 18px;
  color: #1b093d;
  font-family: "Fredoka", sans-serif;
  /* padding: 20px 5px; */
  /* margin-bottom: 10px; */
}
.coursecard__apply {
  position: absolute;
  bottom: -38px;
  right: 0;
}
.coursecard__apply span:hover {
  padding: 12px 16px;
}
.coursecard__apply span {
  font-size: 14px;
  padding: 10px;
  background-color: #e7e7e7;
  color: #222222;
  border-radius: 2px;
  display: inline-block;
  font-weight: 500;
  transition: padding 0.5s ease;
  border-radius: 5px;
}
.no-courses {
  font-family: "Fredoka", sans-serif;
  font-size: 45px;
  text-align: center;
}
/* @media */
@media (max-width: 990px) {
  .coursecard__content {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 766px) {
  .courses-banner__containers {
    padding: 10px 0;
  }
  .courses-banner__header h2 {
    font-size: 35px;
  }
  .coursecard__content {
    grid-template-columns: repeat(1, 1fr);
  }
}
