.header {
  background-color: #ffffff;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 20;
}
.navi {
  display: flex;
  width: 80%;
  max-width: 1440px;
  margin: 0 auto;
  align-items: center;
  position: relative;
}
.logo {
  width: 30%;
}
.logo a {
  display: block;
}
.logo-img {
  width: 220px;
  display: block;
}
.menus {
  width: 70%;
}
.menu {
  width: 100%;
  display: flex;
  list-style: none;
  align-items: center;
  justify-content: space-between;
}
.menu-links {
  text-decoration: none;
  padding: 36px 5px;
  display: block;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #444444;
}
.menu-links__sub {
  display: flex;
  align-items: center;
}
.menu-links__sub-icon {
  margin-left: 5px;
  font-size: 15px;
}
.link-apply {
  border: 2px solid #00afef;
  border-radius: 7px;
  color: #00afef;
  padding: 11px 20px;
}
.link-login {
  border: 2px solid #00afef;
  background-color: #00afef;
  border-radius: 7px;
  color: #ffffff;
  padding: 11px 20px;
}
.dd {
  z-index: 10;
  /* position: relative; */
}
.dd-content {
  position: absolute;
  box-shadow: 2px 2px 10px rgba(165, 165, 165, 0.4);
  border-radius: 10px;
  width: 40%;
  margin: 0 auto;
  left: 60%;
  transform: translateX(-50%);
  display: none;
  z-index: 20;
  background-color: #ffffff;
}
.dd-content {
  padding: 10px 20px;
  list-style: none;
}
.dd-content li a {
  display: block;
  padding: 12px 20px;
  text-decoration: none;
  color: #444444;
  font-size: 15px;
  margin-bottom: 5px;
}
.dd:hover > ul {
  display: initial;
}
.dd-content__header {
  margin-bottom: 25px;
  font-size: 18px;
  font-family: "Fredoka", sans-serif;
  color: #222222;
}
.dd-content__grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 40px 0;
  padding-bottom: 20px;
}
.dd-content__grid a {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 15px;
  color: #222222;
  font-weight: 600;
}
.dd-content__grid a img {
  width: 20px;
  margin-right: 10px;
}
.menu__show-btn {
  cursor: pointer;
  position: absolute;
  right: 5px;
  background: none;
  border: none;
  display: none;
  top: 1px;
}
.menu__show {
  font-size: 35px;
}
/* @media */
@media (max-width: 2440px) {
  /*   .menu-links {
    font-size: 16px;
  } */
}
@media (max-width: 1380px) {
  .logo {
    width: 20%;
  }
  .menus {
    width: 80%;
  }
}
@media (max-width: 1300px) {
  .navi {
    width: 95%;
  }
}
@media (max-width: 1190px) {
  .menu-links {
    margin: 0;
  }
}
@media (max-width: 1080px) {
  .header.active {
    bottom: 0;
    overflow-y: auto;
  }
  .dd-content__grid a img {
    /* display: none; */
    width: 16px;
  }
  .menu__show-btn {
    display: initial;
    margin: 0;
  }
  .navi {
    display: block;
    width: 95%;
  }
  .logo {
    width: 100%;
    margin: 0;
  }
  .header {
    padding: 15px 0;
    box-shadow: 2px 2px 10px rgba(226, 226, 226, 0.4);
  }
  .menus {
    display: none;
  }
  .menu {
    width: 100%;
    position: absolute;
    display: block;
    background-color: #ffffff;
    right: 0;
    top: 59px;
    padding: 0 10px;
  }
  .menus-active {
    display: block;
  }
  .dd-content__grid {
    display: block;
  }
  .dd-content {
    position: initial;
    box-shadow: none;
    border-radius: 10px;
    min-width: 100%;
    display: block;
    z-index: 0;
    border-radius: 0;
    padding: 0;
    max-height: 100%;
    background-color: transparent;
    width: 100%;
    left: 0;
    transform: translateX(0);
  }
  .dd-content div a {
    padding: 12px;
  }
  .menu-links {
    padding: 18px 0;
  }
  .dd-content {
    min-width: 100%;
  }
  .link-login,
  .link-apply {
    margin: 20px 0;
    padding: 13px 5px;
  }
}
@media (max-width: 766px) {
  .banner__container {
    text-align: center;
  }
  .banner-left p {
    max-width: 100% !important;
  }
  .menu__show {
    font-size: 32px;
  }
}
@media (max-width: 500px) {
  .logo-img {
    width: 185px;
  }
}
