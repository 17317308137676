.privacy_policy {
  width: 80%;
  max-width: 1440px;
  margin: 100px auto 20px auto;
}
.privacy_policy_heading {
  padding: 50px 0;
  text-align: center;
}
.privacy_policy_heading p {
  margin-top: 10px;
}
.privacy_policy_content h4,
.privacy_policy_content h2,
.privacy_policy_content h3 {
  margin-top: 20px;
  font-size: 20px;
}
.privacy_policy_content p {
  line-height: 1.7;
}
.privacy_policy_content ul {
  padding-left: 20px;
  display: grid;
  gap: 10px;
  list-style: circle;
  margin-top: 10px;
}
@media (max-width: 768px) {
  .privacy_policy {
    width: 95%;
  }
}
@media (max-width: 280px) {
  .privacy_policy {
    min-width: 280px;
    padding: 10px;
  }
}
