@import url("https://fonts.googleapis.com/css2?family=Fredoka:wght@300;400;600;700&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,500;1,700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* outline: 1px solid red; */
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.err-space {
  padding-top: 180px;
  padding-bottom: 80px;
  text-align: center;
  background-color: #fdfdfd;
}
.sub-header {
  text-align: center;
  margin-bottom: 50px;
}
.sub-header__title {
  font-family: "Montserrat", sans-serif;
  font-size: 35px;
}
.containers {
  width: 80%;
  margin: 50px auto;
  max-width: 1440px;
  min-width: 320px;
}
.container-banner {
  width: 80%;
  margin: 70px auto 0 auto;
  max-width: 1440px;
  padding-top: 10px;
}
.link__btn {
  padding: 13px 20px 14px 20px;
  font-size: 14px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: 500;
  margin-right: 20px;
  display: inline-flex;
  align-items: center;
  margin-right: 20px;
  background: none;
  cursor: pointer;
}
.link__btn-bg {
  background-color: #ffffff;
  border: 1px solid #ffffff;
  color: #00afef;
}
.link__btn-bg-orange {
  background-color: #ffa41d;
  border: 1px solid #ffa41d;
  color: #ffffff;
}
.link__btn-bg-grey {
  background-color: grey;
  border: 1px solid grey;
  color: #ffffff;
}
.link__btn-bg-blue {
  background-color: #00afef;
  border: 1px solid #00afef;
  color: #ffffff;
}
.link__btn-outline {
  border: 1px solid #ffffff;
  color: #ffffff;
}
.link__btn-outline-blue {
  border: 1px solid #00afef;
  color: #00afef;
}
.link__btn-outline-darkblue {
  border: 1px solid #1b093d;
  color: #1b093d;
}
.link__btn-icon {
  margin-left: 5px;
  font-size: 22px;
}
.co__box-grid span {
  font-size: 14px;
}
.err-input {
  border-color: red !important;
}
/* @media */
@media (max-width: 1300px) {
  .containers,
  .container-banner {
    width: 90%;
  }
}
@media (max-width: 766px) {
  .containers {
    margin: 50px auto 0 auto;
  }
}
@media (max-width: 500px) {
  .link__btn {
    margin-bottom: 10px;
    margin-right: 5px;
  }
  .wrapper {
    min-width: 320px;
  }
}
