@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cabin+Sketch:wght@400;700&display=swap");

.smarties {
  margin-top: 70px;
}
.smarties--banner {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.7) 0%, #ffffff 100%), url("../Images/shapes-bg-color.png");
  background-size: cover;
}
.smartiess--banner__sub {
  width: 80%;
  max-width: 1440px;
  margin: auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 120px 0;
}
.smarties--banner__text {
  max-width: 800px;
  width: 80%;
  margin: 0 auto;
  text-align: center;
}
.smarties--banner__text h3 {
  font-family: "Rubik", Helvetica, Arial, Lucida, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 16px;
  color: #ff7355;
  letter-spacing: 5px;
  line-height: 2em;
}
.smarties--banner__text h1 {
  font-family: "Rubik", Helvetica, Arial, Lucida, sans-serif;
  font-weight: 700;
  font-size: 50px;
  color: rgba(0, 0, 0, 0.8);
  line-height: 1.4em;
  padding-top: 10px;
}
.smarties--banner__text p {
  font-family: "Rubik", Helvetica, Arial, Lucida, sans-serif;
  font-size: 18px;
  line-height: 2em;
  font-weight: 500;
  color: #555;
}
.smarties--section1__sub {
  display: flex;
}
.smarties--section1__sub > * {
  width: 100%;
  height: 100%;
}
.smarties--section1__sub1 {
  background-color: #3a5063;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 700px;
}
.smarties--sub1__sub {
  width: 60%;
  margin: auto;
  padding: 100px 0;
}
.smarties--sub1__sub h4 {
  font-family: "Rubik", Helvetica, Arial, Lucida, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  color: #ff9c02;
  letter-spacing: 5px;
  line-height: 1.2em;
  padding-bottom: 20px;
}
.smarties--sub1__sub h2 {
  font-family: "Cabin Sketch", display;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 30px;
  letter-spacing: 3px;
  line-height: 1.4em;
  padding-bottom: 20px;
}
.smarties--sub1__sub p {
  font-family: "Rubik", Helvetica, Arial, Lucida, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 2em;
}
.smarties--section1__sub2 {
  min-height: 700px;
  background: url("../Images/Kids-technical-training-center.png");
  background-size: cover;
  background-position: center;
}
.smarties--section2 {
  background-color: #5b41db;
  min-height: 550px;
  padding: 70px 0;
}
.smarties--section2__sub {
  width: 82%;
  max-width: 1440px;
  height: 100%;
  margin: auto;
  display: flex;
  color: #fff;
  align-items: flex-start;
}
.smarties--section2__sub > * {
  width: 100%;
}
.smarties--section2__sub1 {
  padding-right: 20px;
  padding-left: 20px;
}
.smarties--section2__sub1 h5 {
  font-family: "Rubik", Helvetica, Arial, Lucida, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 5px;
  line-height: 1.2em;
  padding-bottom: 20px;
}
.smarties--section2__sub1 h2 {
  font-family: "Rubik", Helvetica, Arial, Lucida, sans-serif;
  font-weight: 700;
  font-size: 40px;
  line-height: 1.4em;
}
.smarties--section2__sub1 p {
  font-family: "Rubik", Helvetica, Arial, Lucida, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 2em;
}
.smarties--section3 {
  background: url("../Images/smarties-image-2.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.smarties--section3__sub {
  width: 80%;
  max-width: 1440px;
  margin: auto;
  padding: 80px 0;
  display: flex;
  justify-content: flex-end;
}
.smarties--section3__text {
  width: 50%;
  background: #fff;
  border-radius: 10px;
  padding: 70px 60px;
}
.smarties--section3__text h2 {
  font-family: "Rubik", Helvetica, Arial, Lucida, sans-serif;
  font-weight: 700;
  font-size: 40px;
  line-height: 1.4em;
  padding-bottom: 10px;
  color: #222;
}
.smarties--section3__text p {
  font-family: "Rubik", Helvetica, Arial, Lucida, sans-serif;
  font-size: 14px;
  line-height: 2em;
  font-weight: 500;
  color: #666666;
  line-height: 2em;
  padding-bottom: 10px;
}
.smarties--section3__text p strong {
  font-weight: 700;
}
.smarties--section3__text p a {
  color: #2ea3f2;
  text-decoration: none;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.smarties--section3__link {
  display: inline-block;
  margin-top: 20px;
  padding: 20px 25px;
  text-decoration: none;
  color: #ffffff;
  border-color: rgba(65, 65, 144, 0);
  border-radius: 70px;
  font-size: 16px;
  font-family: "Rubik", Helvetica, Arial, Lucida, sans-serif;
  font-weight: 700;
  background-color: #7f5aff;
}
@media (max-width: 1300px) {
  .smarties--sub1__sub {
    width: 85%;
  }
  .smarties--section2__sub {
    width: 95%;
  }
  .smarties--section3__sub {
    width: 95%;
  }
}
@media (max-width: 980px) {
  .smartiess--banner__sub {
    padding: 50px 0;
    width: 100%;
  }
  .smarties--section1__sub {
    flex-direction: column;
  }
  .smarties--section1__sub1 {
    min-height: 500px;
  }
  .smarties--section2__sub {
    flex-direction: column;
  }
  .smarties--section3__text {
    width: 100%;
    padding: 40px 10px;
  }
  .smarties--sub1__sub {
    width: 92%;
    padding: 20px 0;
  }
  .smarties--section2__sub1 {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (max-width: 780px) {
  .smarties--banner__text h1 {
    font-size: 30px;
  }
  .smarties--section2__sub1 h2 {
    font-size: 30px;
    line-height: 1.2em;
    margin-bottom: 10px;
  }
  .smarties--section3__text h2 {
    font-size: 30px;
  }
  .smarties--section3__link {
    padding: 15px 25px;
  }
}
